import { equals } from 'ramda';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import type { DimensionsMapping, MultipleParameterDefinition, ParametersMapping, PathStep, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import {
  createValuePathResolver,
  dimensionsMappingToParametersMapping,
  FILTER_PARAMETER_CURRENT,
  getFieldUtilsHandler,
  getPathLastFieldInformation,
  InstanceReferenceType,
  isDimensionStep,
  isRelationFieldPathConfigurationValid,
  isSingleFieldResolution,
  isSingleValueResolution,
  PathStepType,
} from 'yooi-modules/modules/conceptModule';
import { Concept_Name, NumberField, TimeseriesNumberField } from 'yooi-modules/modules/conceptModule/ids';
import type { FieldStoreObject } from 'yooi-modules/modules/conceptModule/model/types';
import type { DimensionDisplayOption, DimensionExportConfiguration, ViewDimension, WidgetStoreObject } from 'yooi-modules/modules/dashboardModule';
import { Widget_Title } from 'yooi-modules/modules/dashboardModule/ids';
import {
  compareNumber,
  compareProperty,
  compareString,
  comparing,
  computeEffectiveRangeForPeriodAndDates,
  dateFormats,
  formatDisplayDate,
  isRichText,
  joinObjects,
  PeriodicityType,
  richTextToText,
} from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../components/atoms/IconOnlyButton';
import { Icon } from '../../../../components/atoms/icons';
import Typo from '../../../../components/atoms/Typo';
import ConfirmationModal, { ConfirmationModalVariant } from '../../../../components/molecules/ConfirmationModal';
import InlineLoading from '../../../../components/molecules/InlineLoading';
import useStore from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import { HierarchyVariant, SizeContextProvider, SizeVariant } from '../../../../utils/useSizeContext';
import useTheme from '../../../../utils/useTheme';
import withAsyncTask from '../../../../utils/withAsyncTask';
import { getFieldLabel } from '../../fieldUtils';
import { countValidFilters } from '../../filter/filterUtils';
import type { FilterConfiguration } from '../../filter/useFilterSessionStorage';
import { createPathConfigurationHandler } from '../../pathConfigurationHandler';
import { getFieldTypeValidator } from '../../pathConfigurationHandlerUtils';
import useExport from '../../useExport';
import { getViewDimensionsAsParameterDefinitions } from '../common/series/viewWithSeriesFeatureUtils';
import { getDimensionLabel } from '../data/dataResolution';
import type { ViewResolutionError } from '../viewResolutionUtils';
import { isResolutionError } from '../viewResolutionUtils';
import type { LineChartViewResolvedDefinition } from './lineChartViewDefinitionHandler';
import type { LineChartViewResolution } from './lineChartViewResolution';
import { isLineChartLoaded } from './lineChartViewResolution';

interface LineChartViewExportButtonProps {
  widgetId: string | undefined,
  viewDefinition: LineChartViewResolvedDefinition,
  viewDimensions: ViewDimension[],
  getViewResolution: () => LineChartViewResolution | ViewResolutionError,
  parametersMapping: ParametersMapping,
  filterConfiguration: FilterConfiguration | undefined,
  parameterDefinitions: (SingleParameterDefinition | MultipleParameterDefinition)[],
}

interface Header {
  type: 'header',
  key: string,
}

interface DimensionColumn {
  type: 'dimension',
  key: string,
  dimensionId: string,
  label: string | undefined,
  path: PathStep[],
  exportConfiguration: DimensionExportConfiguration | undefined,
}

interface TimeColumn {
  type: 'time',
  key: string,
  label: string | undefined,
}

interface FieldColumn {
  type: 'field',
  key: string,
  conceptDefinitionId: string | undefined,
  fieldId: string,
  label: string | undefined,
  path: PathStep[],
}

interface LineChartExportLine {
  type: 'line',
  key: string,
  dimensionsMapping: DimensionsMapping,
  time: number,
}

type LineChartExportColumn = DimensionColumn | FieldColumn | TimeColumn;
type Line = LineChartExportLine | Header;

const LineChartViewExportButton: FunctionComponent<LineChartViewExportButtonProps> = withAsyncTask(({
  executeAsyncTask,
  widgetId,
  viewDefinition,
  viewDimensions,
  getViewResolution,
  parametersMapping,
  filterConfiguration,
  parameterDefinitions,
}) => {
  const { exportToExcel } = useExport<LineChartExportColumn, Line>(executeAsyncTask);
  const store = useStore();
  const theme = useTheme();
  const widget = widgetId ? store.getObjectOrNull<WidgetStoreObject>(widgetId) : undefined;
  const widgetName = widget ? widget[Widget_Title] : undefined;

  const withFilters = filterConfiguration !== undefined && ((filterConfiguration.filters && Object.values(filterConfiguration.filters)
    .some((filter) => countValidFilters(store, filter) > 0))
    || filterConfiguration.nameSearch !== undefined);

  const [pendingExport, setPendingExport] = useState<{
    lines: Line[],
    columns: LineChartExportColumn[],
    fileName: string,
    elementsInError: string[],
  } | undefined>(undefined);

  const exportView = (lines: Line[], columns: LineChartExportColumn[], fileName: string) => {
    exportToExcel({
      fileName: `${formatDisplayDate(new Date(), dateFormats.isoDateFormat)},${fileName}.xlsx`,
      headerRowCount: 1,
      lines,
      columns,
      cellResolver: (objectStore, line, column) => {
        if (line.type === 'header') {
          return { format: 'string', value: column.label };
        }
        if (column.type === 'time') {
          return { format: 'date', value: line.time, period: PeriodicityType.day };
        }
        const valuePathResolver = createValuePathResolver(objectStore, joinObjects(parametersMapping, dimensionsMappingToParametersMapping(line.dimensionsMapping)));
        if (column.type === 'dimension') {
          const { exportConfiguration } = column;
          const defaultPath: PathStep[] = [
            {
              type: PathStepType.dimension,
              conceptDefinitionId: column.dimensionId,
            },
            { type: PathStepType.mapping, mapping: { type: InstanceReferenceType.parameter, id: FILTER_PARAMETER_CURRENT } },
            { type: PathStepType.field, fieldId: Concept_Name },
          ];
          if (exportConfiguration && exportConfiguration.type === 'uuid') {
            return { format: 'string', value: line.dimensionsMapping[column.key] };
          } else {
            const path = exportConfiguration?.path ?? defaultPath;
            const exportPathResolution = createValuePathResolver(objectStore, { [FILTER_PARAMETER_CURRENT]: { type: 'single', id: line.dimensionsMapping[column.key] } })
              .resolvePathValue(path);
            if (exportPathResolution && isSingleValueResolution(exportPathResolution) && exportPathResolution.value) {
              return { format: 'string', value: isRichText(exportPathResolution.value) ? richTextToText(exportPathResolution.value) : exportPathResolution.value as string };
            } else {
              return { format: 'string', value: undefined };
            }
          }
        }
        if (column.type === 'field') {
          const pathResolution = valuePathResolver.resolvePathField(column.path);
          if (isSingleFieldResolution(pathResolution) && pathResolution.dimensionsMapping) {
            const fieldUtilsHandler = getFieldUtilsHandler(objectStore, column.fieldId);
            if (!fieldUtilsHandler.getExportValue) {
              return undefined;
            }
            return fieldUtilsHandler.getExportValue(pathResolution.dimensionsMapping, { time: line.time }, {});
          }
        }
        return undefined;
      },
    });
  };

  const prepareExportView = () => {
    const viewResolution = getViewResolution();
    if (isResolutionError(viewResolution) || !isLineChartLoaded(viewResolution)) {
      return;
    }
    const columns: LineChartExportColumn[] = [{
      type: 'time',
      key: 'time',
      label: 'Time',
    }];

    const elementsInError: string[] = [];
    const linesWithoutHeader: LineChartExportLine[] = [];

    viewDimensions.forEach((viewDimension, index) => {
      const dimensionDisplay: DimensionDisplayOption | undefined = viewDefinition.getDimensionDisplay(viewDimension);
      let dimensionColumn = {
        type: 'dimension',
        key: viewDimension.id,
      };
      const { exportConfiguration } = dimensionDisplay;
      if (exportConfiguration && exportConfiguration.type === 'path' && !isRelationFieldPathConfigurationValid(store, exportConfiguration, parameterDefinitions)) {
        elementsInError.push(viewDimension.label ?? getDimensionLabel(store, viewDimension.label, index, viewDimension.path));
      } else {
        if (dimensionDisplay && exportConfiguration) {
          dimensionColumn = joinObjects(dimensionColumn, { exportConfiguration });
        }
        if (viewDimension.path[0] && isDimensionStep(viewDimension.path[0])) {
          dimensionColumn = joinObjects(dimensionColumn, {
            label: viewDimension.label ?? getDimensionLabel(store, viewDimension.label, index, viewDimension.path),
            path: viewDimension.path,
            dimensionId: viewDimension.path[0].conceptDefinitionId,
          });
        }
        columns.push(dimensionColumn as LineChartExportColumn);
      }
    });

    if (viewDefinition.series) {
      const pathHandler = createPathConfigurationHandler(store, [...parameterDefinitions, ...getViewDimensionsAsParameterDefinitions(store, viewDimensions)], [getFieldTypeValidator(store, [NumberField, TimeseriesNumberField], i18n`Input should end with a number.`)]);
      viewDefinition.series.forEach((serie) => {
        const lastFieldInfo = getPathLastFieldInformation(serie.path);
        let { label } = serie;
        if (lastFieldInfo && !label) {
          const fieldObject = lastFieldInfo?.fieldId && store.getObjectOrNull<FieldStoreObject>(lastFieldInfo.fieldId);
          label = fieldObject ? getFieldLabel(store, fieldObject) : undefined;
        }
        if (lastFieldInfo && !pathHandler.getErrors(serie.path)) {
          const fieldColumn: LineChartExportColumn = {
            type: 'field',
            key: serie.id,
            conceptDefinitionId: lastFieldInfo.conceptDefinitionId,
            fieldId: lastFieldInfo.fieldId,
            label,
            path: serie.path,
          };
          columns.push(fieldColumn);
        } else {
          elementsInError.push(label ?? serie.id);
        }
      });
    }

    const lineKeys: string[] = [];
    const rowDimensionsMappings: DimensionsMapping[] = [];
    const times: number[] = [];
    viewResolution.series.forEach((serie) => {
      serie.rows.forEach((row) => {
        row.stackedRows.forEach((stackedRow) => {
          if (!rowDimensionsMappings.some((object) => equals(object, stackedRow.info.rowDimensionsMapping))) {
            rowDimensionsMappings.push(stackedRow.info.rowDimensionsMapping);
          }
          const dimensionKey = serie.key.split('|')[0];
          const lineDimensionMapping = joinObjects(stackedRow.info.rowDimensionsMapping, { [dimensionKey]: Object.values(stackedRow.info.dimensionsMapping)[0] });
          stackedRow.values.forEach((value) => {
            const lineKey = [value.time, Object.values(stackedRow.info.rowDimensionsMapping).join('|')].join('|');
            if (viewDefinition.dateRange) {
              const {
                from: startDate,
                to: endDate,
                error,
              } = computeEffectiveRangeForPeriodAndDates(viewDefinition.dateRange.period, viewDefinition.dateRange.from, viewDefinition.dateRange.to);
              if (error || !startDate || !endDate || value.time < startDate?.getTime() || value.time > endDate?.getTime()) {
                return;
              }
              if (!times.includes(value.time)) {
                times.push(value.time);
              }
              if (!lineKeys.includes(lineKey)) {
                lineKeys.push(lineKey);
                const line: LineChartExportLine = {
                  type: 'line',
                  key: lineKey,
                  dimensionsMapping: lineDimensionMapping,
                  time: value.time,
                };
                linesWithoutHeader.push(line);
              } else {
                const lineIndex = linesWithoutHeader.findIndex((line) => line.key === lineKey);
                linesWithoutHeader[lineIndex].dimensionsMapping = joinObjects(linesWithoutHeader[lineIndex].dimensionsMapping, lineDimensionMapping);
              }
            }
          });
        });
      });
    });

    const expectedLines: LineChartExportLine[] = [];
    for (let i = 0; i < times.length; i += 1) {
      for (let j = 0; j < rowDimensionsMappings.length; j += 1) {
        expectedLines.push({
          type: 'line',
          key: [times[i].toString(), Object.values(rowDimensionsMappings[j]).join('|')].join('|'),
          time: times[i],
          dimensionsMapping: rowDimensionsMappings[j],
        });
      }
    }

    expectedLines.forEach((expectedLine) => {
      if (!lineKeys.includes(expectedLine.key)) {
        const noValueLine: LineChartExportLine = {
          type: 'line',
          key: expectedLine.key,
          dimensionsMapping: expectedLine.dimensionsMapping,
          time: expectedLine.time,
        };
        linesWithoutHeader.push(noValueLine);
      }
    });
    linesWithoutHeader.sort(comparing<LineChartExportLine>(compareProperty('time', compareNumber)).thenComparing(compareProperty('key', compareString)));
    const lines: Line[] = linesWithoutHeader;
    lines.unshift({ type: 'header', key: 'header' });

    let fileName = i18n`export`;
    if (viewDefinition.exportTitle) {
      fileName = viewDefinition.exportTitle;
    } else if (widgetName) {
      fileName = `${widgetName}-${fileName}`;
    }
    if (elementsInError.length === 0 && !withFilters) {
      exportView(lines, columns, fileName);
    } else {
      setPendingExport({ lines, columns, fileName, elementsInError });
    }
  };

  return (
    <>
      <SizeContextProvider sizeVariant={SizeVariant.small} hierarchyVariant={HierarchyVariant.content}>
        <IconOnlyButton
          tooltip={i18n`Export`}
          iconName={IconName.file_save_outline}
          variant={IconOnlyButtonVariants.secondary}
          onClick={() => {
            prepareExportView();
          }}
        />
      </SizeContextProvider>
      {pendingExport && (
        <ConfirmationModal
          variant={ConfirmationModalVariant.confirm}
          title={i18n`Warning`}
          titleIcon={{ name: Icon.warning, color: theme.color.background.warning.default }}
          open
          onConfirm={() => {
            if (pendingExport) {
              exportView(pendingExport.lines, pendingExport.columns, pendingExport.fileName);
              setPendingExport(undefined);
            }
          }}
          confirmLabel={i18n`Continue`}
          cancelLabel={i18n`Cancel`}
          onCancel={() => setPendingExport(undefined)}
          render={() => <Typo>{i18n`${pendingExport && pendingExport.elementsInError.length > 0 ? i18n`${pendingExport.elementsInError.length} series will not be exported (${pendingExport.elementsInError.join(', ')}) because the export configuration is corrupted. If you wish to export them, you must ask an administrator.` : ''}${pendingExport && pendingExport.elementsInError.length > 0 && withFilters ? '\n' : ''}${withFilters ? i18n`Filters configured on this view will be taken into account when exporting data. Are you sure you want to continue?` : ''}`}</Typo>}
        />
      )}
    </>
  );
}, InlineLoading);

export default LineChartViewExportButton;
