import type { FunctionComponent } from 'react';
import type { TimeseriesExportConfiguration } from 'yooi-modules/modules/conceptModule/fields';
import { joinObjects, PeriodicityType } from 'yooi-utils';
import Checkbox from '../../../../components/atoms/Checkbox';
import Icon, { IconColorVariant, IconName } from '../../../../components/atoms/Icon';
import Tooltip from '../../../../components/atoms/Tooltip';
import Typo from '../../../../components/atoms/Typo';
import DateRange from '../../../../components/inputs/datePickers/DateRange';
import { DateRangeOptions } from '../../../../components/inputs/datePickers/DateRangePredefined';
import SimpleInput from '../../../../components/inputs/strategy/SimpleInput';
import CompositeField from '../../../../components/molecules/CompositeField';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import SpacedContainer from '../../../../components/molecules/SpacedContainer';
import { getSpacing, Spacing } from '../../../../theme/spacingDefinition';
import { formatDateRange, getPeriodicityOptions } from '../../../../utils/dateUtilsFront';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';

interface TimeseriesExportConfigurationInputProps {
  configuration: TimeseriesExportConfiguration | undefined,
  onChange: (configuration: TimeseriesExportConfiguration) => void,
}

const useStyles = makeStyles({
  titleContainer: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: getSpacing(Spacing.xs),
  },
}, 'timeseriesExportConfigurationInput');

const TimeseriesExportConfigurationInput: FunctionComponent<TimeseriesExportConfigurationInputProps> = ({ configuration, onChange }) => {
  const classes = useStyles();

  if (configuration?.asNumber) {
    return (
      <SpacedContainer margin={{ x: Spacing.splus }}>
        <Tooltip title={i18n`Number`}>
          <Typo maxLine={1}>{i18n`Number`}</Typo>
        </Tooltip>
      </SpacedContainer>
    );
  }

  const { from, to, text } = formatDateRange(configuration?.dateRange, undefined, PeriodicityType.day);
  const errors: string[] = [];
  if (!from && !to) {
    errors.push(i18n`Missing date range`);
  }
  const periodicityOptions = getPeriodicityOptions();
  const title = i18n`Dates${text ? ` - ${text}` : ''}`;
  return (
    <Tooltip title={title}>
      <CompositeField
        headerLinesRenderers={[{
          id: 'title',
          render: () => (
            <div className={classes.titleContainer}>
              <Typo maxLine={1}>{title}</Typo>
              {errors && errors.length > 0 && (
                <Icon name={IconName.error} colorVariant={IconColorVariant.error} tooltip={errors.join('\n')} />
              )}
            </div>
          ),
        }]}
        getDropdownSectionDefinitions={() => [
          {
            id: 'edition',
            lines: [
              {
                id: 'dateRange',
                title: i18n`Date range`,
                render: (
                  <SimpleInput
                    initialValue={configuration?.dateRange}
                    onSubmit={(dateRange) => {
                      onChange(joinObjects(configuration, { dateRange }));
                    }}
                  >
                    {({ ...props }) => (
                      <DateRange
                        {...props}
                        placeholder={i18n`Add a date range`}
                        defaultPeriodicity={PeriodicityType.day}
                        predefinedRangeOption={DateRangeOptions.lastAndCurrent}
                        withPredefinedRanges
                        withLastAndNext
                      />
                    )}
                  </SimpleInput>
                ),
              },
              {
                id: 'granularity',
                title: i18n`Granularity`,
                render: (
                  <SearchAndSelect
                    selectedOption={periodicityOptions.find(({ id }) => id === (configuration?.granularity ?? PeriodicityType.day))}
                    computeOptions={() => periodicityOptions}
                    onSelect={(option) => {
                      if (option) {
                        onChange(joinObjects(configuration, { granularity: option.id }));
                      }
                    }}
                  />),
              },
              {
                id: 'showEmpty',
                title: i18n`Export dates without value`,
                info: i18n`Checking this will force display of date within time range even if they have no data.`,
                render: (<Checkbox
                  checked={Boolean(configuration?.withEmptyValue)}
                  onChange={(withEmptyValue) => {
                    onChange(joinObjects(configuration, { withEmptyValue }));
                  }}
                />),
              },
              {
                id: 'showNonAlign',
                title: i18n`Export dates not aligned with granularity`,
                info: i18n`Checking this will display dates that are not aligned with the given granularity, without this dates with different granularity cannot be added.`,
                render: (<Checkbox
                  checked={!configuration?.hideNonAlignValue}
                  onChange={(showNonAlignValue) => {
                    onChange(joinObjects(configuration, { hideNonAlignValue: !showNonAlignValue }));
                  }}
                />),
              },
            ],
          },
        ]}
      />
    </Tooltip>
  );
};

export default TimeseriesExportConfigurationInput;
