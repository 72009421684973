import type { BusinessRuleHandler, ObjectStoreReadOnly, ObjectStoreWithTimeseries, TimeRange, TimeseriesValue } from 'yooi-store';
import { ValidationStatus } from 'yooi-store';
import { isFiniteNumber, isNumber, joinObjects, numberType } from 'yooi-utils';
import { asImport, CommonAsType } from '../../../common/fields/commonPropertyType';
import type { DslConfigurationHandler, GetDslFieldHandler, UpdateOperationHandlers } from '../../../common/fields/FieldModuleDslType';
import { ResolutionTypeError } from '../../../common/typeErrorUtils';
import { preventComputedFieldUpdate, validateFieldIdAsProperty, validateIntegrationOnlyPropertyUpdate } from '../../common/commonFieldUtils';
import { registerField } from '../../module';
import type { FilterValueRaw, PathStep } from '../../moduleType';
import { FilterValueType } from '../../moduleType';
import type {
  DimensionsMapping,
  FieldFilterCondition,
  FieldFilterConditions,
  MultipleParameterValue,
  ParametersMapping,
  ResolutionStack,
  SingleParameterValue,
  ValueResolution,
} from '../../utils';
import {
  buildDimensionalId,
  createValuePathResolver,
  isFilterValueRaw,
  isSingleValueResolution,
  isValueResolutionOfType,
  ParsedDimensionType,
  parseDimensionMapping,
  resolveFieldValue,
} from '../../utils';
import { arrhythmicTimeseriesOf } from '../../utils/formula/timeseriesFunctions';
import type { NumberField } from '../types';

const isTimeseriesValueNumber = (value: unknown): value is TimeseriesValue<number | null | undefined> => {
  if (value === null || typeof value !== 'object') {
    return false;
  } else if (!Object.keys(value).every((key) => ['time', 'value'].includes(key)) || !['time', 'value'].every((key) => Object.keys(value).includes(key))) {
    return false;
  } else if (typeof (value as { time: unknown }).time !== 'number') {
    return false;
  } else if ((value as { value: unknown }).value !== null && (value as { value: unknown }).value !== undefined && typeof (value as { value: unknown }).value !== 'number') {
    return false;
  } else {
    return true;
  }
};

const checkNumberField = ({ getObjectOrNull }: ObjectStoreReadOnly, propertyId: string): BusinessRuleHandler => (_, { properties }) => {
  const propertyInstance = getObjectOrNull(propertyId);
  if (propertyInstance) {
    const propertyValue = properties?.[propertyId];
    if (propertyValue !== undefined) { // Check with undefined as we want to validate null updates
      if (propertyValue === null || (isFiniteNumber(propertyValue) && typeof propertyValue === 'number')) {
        return { rule: 'field.numberField.allowNumber', status: ValidationStatus.ACCEPTED };
      } else {
        return { rule: 'field.numberField.InvalidNumber', status: ValidationStatus.REJECTED };
      }
    }
  }
  return undefined;
};

const getValueResolution = (
  objectStore: ObjectStoreWithTimeseries,
  { resolveConfigurationWithOverride }: DslConfigurationHandler<NumberField>,
  fieldId: string,
  dimensionsMapping: DimensionsMapping,
  resolutionStack?: ResolutionStack,
  timeRange?: TimeRange,
  isTimeseries?: boolean
): ValueResolution<number | TimeseriesValue<number | null | undefined>[] | undefined> => {
  const valueResolution = resolveFieldValue(objectStore, fieldId, dimensionsMapping, resolutionStack, timeRange, isTimeseries);
  if (isValueResolutionOfType(valueResolution, (value): value is number | undefined => value === undefined || typeof value === 'number')) {
    const numberField = resolveConfigurationWithOverride(dimensionsMapping);

    const getDisplayValue = (): number | undefined => {
      let preciseValue;
      const isValidInteger: (v: unknown) => boolean = (v) => Number.isInteger(v) && Number.isSafeInteger(v);
      const isValidFloat: (v: unknown) => boolean = (v) => Number(v) === v && !Number.isInteger(v);
      if (valueResolution.value != null && (isValidInteger(valueResolution.value) || isValidFloat(valueResolution.value))) {
        // keep only 15 significant digits precision to match IEEE754 standard (see https://en.wikipedia.org/wiki/Double-precision_floating-point_format)
        preciseValue = +(valueResolution.value as unknown as number).toPrecision(15);
      }
      let displayValue;
      if (preciseValue != null) {
        if (valueResolution.isComputed) {
          const { decimals } = numberField;
          const fractionDigits = decimals !== undefined && Number.isSafeInteger(decimals) && decimals >= 0 ? decimals : 1;
          if (isFiniteNumber(preciseValue)) {
            displayValue = Number(preciseValue.toLocaleString('en-US', {
              useGrouping: false,
              maximumFractionDigits: fractionDigits,
              minimumFractionDigits: fractionDigits,
            }));
          }
        } else {
          displayValue = preciseValue;
        }
      }
      return displayValue;
    };

    // in order to keep the maximum precision during intermediate computing, processComputedValue is called only when exposing the value
    return joinObjects(valueResolution, { getDisplayValue });
  } else if (isValueResolutionOfType(valueResolution, (value): value is null => value === null)) {
    return { value: undefined, isComputed: valueResolution.isComputed, error: valueResolution.error, getDisplayValue: () => undefined, isTimeseries: valueResolution.isTimeseries };
  } else if (isValueResolutionOfType(
    valueResolution,
    (value): value is TimeseriesValue<number | null | undefined>[] | undefined => value === undefined || (Array.isArray(value) && value.every(isTimeseriesValueNumber))
  )) {
    return valueResolution;
  } else {
    return {
      value: undefined,
      isComputed: valueResolution.isComputed,
      error: valueResolution.error ?? new ResolutionTypeError(['number', 'TimeseriesValue', 'null', 'undefined'], typeof valueResolution.value),
      getDisplayValue: () => undefined,
      isTimeseries: valueResolution.isTimeseries,
    };
  }
};

const sanitizeNumberValue = (value: unknown) => (value ?? { type: FilterValueType.raw, raw: undefined }) as FilterValueRaw<number | undefined>;
const isNumberFilterApplicable = (value: FilterValueRaw<number | undefined>) => Boolean(value) && isFilterValueRaw<number | undefined>(value);

type NumberFieldStoreValue = number | undefined;
type NumberFieldResolvedValue = number | TimeseriesValue<number | null | undefined>[] | undefined;
type NumberFieldRestValue = number | undefined;

interface NumberFieldFilterConditions extends FieldFilterConditions<NumberFieldResolvedValue> {
  EQUALS: FieldFilterCondition<number | undefined, NumberFieldResolvedValue>,
  NOT_EQUALS: FieldFilterCondition<number | undefined, NumberFieldResolvedValue>,
  GREATER: FieldFilterCondition<number | undefined, NumberFieldResolvedValue>,
  GREATER_OR_EQUALS: FieldFilterCondition<number | undefined, NumberFieldResolvedValue>,
  LOWER: FieldFilterCondition<number | undefined, NumberFieldResolvedValue>,
  LOWER_OR_EQUALS: FieldFilterCondition<number | undefined, NumberFieldResolvedValue>,
  IS_EMPTY: FieldFilterCondition<undefined, NumberFieldResolvedValue>,
  IS_NOT_EMPTY: FieldFilterCondition<undefined, NumberFieldResolvedValue>,
}

interface NumberUpdateConfiguration {
  INITIALIZE: { type: 'value', value: NumberFieldStoreValue } | { type: 'path', path: PathStep[] },
  REPLACE: { type: 'value', value: NumberFieldStoreValue } | { type: 'path', path: PathStep[] },
  INCREMENT: { type: 'value', value: NumberFieldStoreValue } | { type: 'path', path: PathStep[] },
  CLEAR: undefined,
}

export interface NumberExportConfiguration {
  time?: number,
}

type NumberFieldHandler = GetDslFieldHandler<
  NumberField,
  NumberFieldStoreValue,
  number | null,
  NumberFieldStoreValue,
  NumberFieldResolvedValue,
  NumberFieldRestValue,
  NumberFieldFilterConditions,
  NumberUpdateConfiguration,
  undefined,
  NumberExportConfiguration
>;
export const numberFieldHandler: NumberFieldHandler = registerField({
  model: {
    label: 'NumberField',
    title: 'Number',
    withApiAlias: true,
    properties: [
      { label: 'MinValue', as: asImport('NumberColorStepValue', 'modules/conceptModule/moduleType'), supportLocalOverride: true },
      { label: 'MaxValue', as: asImport('NumberColorStepValue', 'modules/conceptModule/moduleType'), supportLocalOverride: true },
      { label: 'RangeValue', as: asImport('NumberColorStepsValue', 'modules/conceptModule/moduleType', true), supportLocalOverride: true },
      { label: 'Unit', as: CommonAsType.string, supportLocalOverride: true },
      { label: 'Decimals', as: CommonAsType.number, supportLocalOverride: true },
      { label: 'InvalidColor', as: CommonAsType.string, supportLocalOverride: true },
    ],
    asPropertyBusinessRules: [
      checkNumberField,
      validateIntegrationOnlyPropertyUpdate('numberField'),
      validateFieldIdAsProperty('numberField'),
      preventComputedFieldUpdate('numberField'),
    ],
  },
  handler: (objectStore, fieldId, configurationHandler) => {
    const getValueAsText = (dimensionsMapping: DimensionsMapping) => {
      const numberField = configurationHandler.resolveConfigurationWithOverride(dimensionsMapping);
      const value = getValueResolution(objectStore, configurationHandler, fieldId, dimensionsMapping)?.getDisplayValue();
      return (((value || value === 0) && numberField.unit) ? `${value} ${numberField.unit}` : value?.toString());
    };

    const getStoreValue = (dimensionsMapping: DimensionsMapping): number | undefined => {
      const parsedDimensions = parseDimensionMapping(dimensionsMapping);
      if (parsedDimensions.type === ParsedDimensionType.MonoDimensional) {
        return objectStore.getObject(parsedDimensions.objectId)[fieldId] as number | undefined;
      } else {
        return objectStore.getObject(buildDimensionalId(dimensionsMapping), true)[fieldId] as number | undefined;
      }
    };

    const extractOperationValueNumber = (
      parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>,
      operationValue: { type: 'value', value: NumberFieldStoreValue } | { type: 'path', path: PathStep[] }
    ): number | undefined => {
      if (operationValue.type === 'path') {
        const { path } = operationValue;
        const resolution = createValuePathResolver(objectStore, parametersMapping).resolvePathValue(path);
        if (isSingleValueResolution(resolution)) {
          const resolvedValue = resolution.value;
          if (isFiniteNumber(resolvedValue) && isNumber(resolvedValue)) {
            return resolvedValue;
          }
        }
        return undefined;
      } else {
        return operationValue.value;
      }
    };

    return {
      describe: () => ({ hasData: true, returnType: numberType, timeseriesMode: configurationHandler.resolveConfiguration().formula === undefined ? 'implicit' : 'none' }),
      restApi: {
        returnTypeSchema: { type: 'number', nullable: true },
        formatValue: (value) => (typeof value === 'number' ? value : undefined),
      },
      getStoreValue,
      getValueWithoutFormula: getStoreValue,
      getTimeseriesValueWithoutFormula: (objectId, timeRange) => objectStore.getTimeseries(objectId, fieldId, timeRange) as TimeseriesValue<NumberFieldStoreValue>[],
      getValueResolution: (dimensionsMapping, resolutionStack, timeRange, isTimeseries) => (
        getValueResolution(objectStore, configurationHandler, fieldId, dimensionsMapping, resolutionStack, timeRange, isTimeseries)
      ),
      updateValue: (dimensionsMapping, value) => {
        const parsedDimensionMapping = parseDimensionMapping(dimensionsMapping);
        if (parsedDimensionMapping.type === ParsedDimensionType.MonoDimensional) {
          objectStore.updateObject(parsedDimensionMapping.objectId, { [fieldId]: value });
        } else {
          objectStore.updateObject(buildDimensionalId(dimensionsMapping), { [fieldId]: value });
        }
      },
      isEmpty: (dimensionsMapping) => {
        const { value } = getValueResolution(objectStore, configurationHandler, fieldId, dimensionsMapping);
        return value == null || !isFiniteNumber(value);
      },
      isSaneValue: () => ({ isValid: true }),
      getValueAsText,
      getExportColumnHeaders: (configuration, fieldLabel) => ({
        columnsNumber: 1,
        getHeaders: () => [{ format: 'string', value: fieldLabel }],
        getColumnConfiguration: () => configuration,
      }),
      getExportValue: (dimensionsMapping, configuration) => {
        const numberField = configurationHandler.resolveConfigurationWithOverride(dimensionsMapping);
        let value;
        if (!configuration?.time) {
          value = getValueResolution(objectStore, configurationHandler, fieldId, dimensionsMapping).value;
        } else {
          const timeseriesValue = getValueResolution(objectStore, configurationHandler, fieldId, dimensionsMapping, undefined, {
            from: configuration.time,
            to: configuration.time,
          }, true).value;
          if (Array.isArray(timeseriesValue)) {
            value = timeseriesValue?.find(({ time }) => time === configuration.time)?.value;
          }
        }
        return { format: 'number', value: isFiniteNumber(value) ? value : undefined, decimal: numberField.decimals, unit: numberField.unit };
      },
      getValueProxy: (dimensionsMapping) => new Proxy({}, {
        get(_, prop) {
          if (prop === 'toString' || prop === Symbol.toStringTag) {
            return () => getValueAsText(dimensionsMapping) ?? '';
          } else {
            return undefined;
          }
        },
      }),
      filterConditions: {
        EQUALS: {
          sanitizeValue: sanitizeNumberValue,
          isFilterApplicable: isNumberFilterApplicable,
          filterFunction: (leftValue, rightValue) => (leftValue ?? 0) === rightValue,
        },
        NOT_EQUALS: {
          sanitizeValue: sanitizeNumberValue,
          isFilterApplicable: isNumberFilterApplicable,
          filterFunction: (leftValue, rightValue) => (leftValue ?? 0) !== rightValue,
        },
        GREATER: {
          sanitizeValue: sanitizeNumberValue,
          isFilterApplicable: isNumberFilterApplicable,
          filterFunction: (leftValue, rightValue) => {
            if (!isFiniteNumber(rightValue)) {
              return false;
            }
            return Number(leftValue ?? 0) > Number(rightValue);
          },
        },
        GREATER_OR_EQUALS: {
          sanitizeValue: sanitizeNumberValue,
          isFilterApplicable: isNumberFilterApplicable,
          filterFunction: (leftValue, rightValue) => {
            if (!isFiniteNumber(rightValue)) {
              return false;
            }
            return Number(leftValue ?? 0) >= Number(rightValue);
          },
        },
        LOWER: {
          sanitizeValue: sanitizeNumberValue,
          isFilterApplicable: isNumberFilterApplicable,
          filterFunction: (leftValue, rightValue) => {
            if (!isFiniteNumber(rightValue)) {
              return false;
            }
            return Number(leftValue ?? 0) < Number(rightValue);
          },
        },
        LOWER_OR_EQUALS: {
          sanitizeValue: sanitizeNumberValue,
          isFilterApplicable: isNumberFilterApplicable,
          filterFunction: (leftValue, rightValue) => {
            if (!isFiniteNumber(rightValue)) {
              return false;
            }
            return Number(leftValue ?? 0) <= Number(rightValue);
          },
        },
        IS_EMPTY: {
          sanitizeValue: () => ({ type: FilterValueType.raw, raw: undefined }),
          isFilterApplicable: () => true,
          filterFunction: (leftValue) => leftValue === undefined,
        },
        IS_NOT_EMPTY: {
          sanitizeValue: () => ({ type: FilterValueType.raw, raw: undefined }),
          isFilterApplicable: () => true,
          filterFunction: (leftValue) => leftValue !== undefined,
        },
      },
      resolvePathStepConfiguration: () => ({
        hasData: true,
        timeseriesMode: 'implicit',
        getValueResolutionType: () => numberType,
        resolveValue: (dimensionsMapping, _, resolutionStack) => {
          const { value, error } = getValueResolution(objectStore, configurationHandler, fieldId, dimensionsMapping, resolutionStack);
          if (error) {
            throw error;
          } else {
            return value;
          }
        },
        getTimeseriesResolutionType: () => arrhythmicTimeseriesOf(numberType),
        resolveTimeseries: (dimensionsMapping, _, resolutionStack, dateRange) => {
          const { value, error } = getValueResolution(objectStore, configurationHandler, fieldId, dimensionsMapping, resolutionStack, dateRange, true);
          if (error) {
            throw error;
          } else {
            return value as TimeseriesValue<number | null | undefined>[] | undefined;
          }
        },
      }),
      updateOperationHandlers: {
        INITIALIZE: {
          applyOperation: (dimensionsMapping, parametersMapping, value) => {
            const fieldHandler = numberFieldHandler(objectStore, fieldId);
            if (fieldHandler.getStoreValue(dimensionsMapping) !== undefined) {
              return;
            }
            const number = extractOperationValueNumber(parametersMapping, value);
            fieldHandler.updateValue(dimensionsMapping, number ?? null);
          },
          sanitizeOperation: (oldOperation) => oldOperation?.payload ?? { type: 'value', value: undefined },
        },
        REPLACE: {
          applyOperation: (dimensionsMapping, parametersMapping, value) => {
            const fieldHandler = numberFieldHandler(objectStore, fieldId);
            const number = extractOperationValueNumber(parametersMapping, value);
            fieldHandler.updateValue(dimensionsMapping, number ?? null);
          },
          sanitizeOperation: (oldOperation) => oldOperation?.payload ?? { type: 'value', value: undefined },
        },
        INCREMENT: {
          applyOperation: (dimensionsMapping, parametersMapping, value) => {
            const fieldHandler = numberFieldHandler(objectStore, fieldId);
            const number = extractOperationValueNumber(parametersMapping, value);
            if (number) {
              const oldValue = fieldHandler.getStoreValue(dimensionsMapping) ?? 0;
              fieldHandler.updateValue(dimensionsMapping, oldValue + number);
            }
          },
          sanitizeOperation: (oldOperation) => oldOperation?.payload ?? { type: 'value', value: undefined },
        },
        CLEAR: {
          applyOperation: (dimensionsMapping) => {
            numberFieldHandler(objectStore, fieldId).updateValue(dimensionsMapping, null);
          },
          sanitizeOperation: () => undefined,
        },
      } satisfies UpdateOperationHandlers<NumberUpdateConfiguration>,
    };
  },
  historyEventProducer: ({ getObjectOrNull }, fieldId) => ({
    value: {
      collectImpactedInstances: ({ id, properties }) => {
        if (
          properties?.[fieldId] !== undefined // A value is in the update for the current field
          || (properties === null && getObjectOrNull(id)?.[fieldId] !== undefined) // Object is deleted and store had a value for the field
        ) {
          return [id];
        } else {
          return [];
        }
      },
      getValue: (id) => ({ value: (getObjectOrNull(id)?.[fieldId] as number | undefined) ?? null, version: 1 }),
      areValuesEquals: (value1, value2) => value1?.value === value2?.value,
    },
  }),
});

export const testables = {
  checkNumberField,
};
