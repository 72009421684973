import { getPathReturnedConceptDefinitionId } from 'yooi-modules/modules/conceptModule';
import { Concept } from 'yooi-modules/modules/conceptModule/ids';
import type { DefaultConfigurationFeatureDefinition, ViewDimension, ViewStoredDefinition } from 'yooi-modules/modules/dashboardModule';
import type { FrontObjectStore } from '../../../../../store/useStore';
import i18n from '../../../../../utils/i18n';
import type { LineEditionOption } from '../../../fields/FieldEditionOptionType';
import { EditionOptionTypes } from '../../../fields/FieldEditionOptionType';
import type { UpdateViewDefinition } from '../../../fields/viewsField/ViewsFieldDefinitionOptions';
import type { ViewResolvedDefinition } from '../../viewResolvedDefinition';
import type { DefaultConfig, DefaultConfigError, DefaultConfigurationFeatureResolvedDefinition } from './defaultConfigurationFeatureDefinition';
import { isDefaultConfigError } from './defaultConfigurationFeatureDefinition';

export const getDefaultConfig = (
  store: FrontObjectStore,
  viewDefinition: DefaultConfigurationFeatureDefinition,
  viewDimensions: ViewDimension[]
): DefaultConfig | DefaultConfigError => {
  if (viewDimensions.length !== 1) {
    return { error: i18n`Default configuration is only supported on exactly one dimension` };
  }
  const viewDimension = viewDimensions.at(0);
  if (!viewDimension) {
    return { error: i18n`missing dimension` };
  }
  const conceptDefinitionId = viewDimension ? getPathReturnedConceptDefinitionId(store, viewDimension.path) : undefined;
  if (!conceptDefinitionId || conceptDefinitionId === Concept) {
    return { error: i18n`dimension do not target a single concept` };
  }
  return { checked: !viewDefinition.withCustomConfig, conceptDefinitionId, viewDimensionId: viewDimension.id };
};

export const getDefaultConfigEditionOption = <T extends ViewStoredDefinition & DefaultConfigurationFeatureDefinition>(
  viewDefinition: ViewResolvedDefinition & DefaultConfigurationFeatureResolvedDefinition,
  readOnly: boolean,
  updateViewDefinition: UpdateViewDefinition<T>
): LineEditionOption => {
  const { defaultConfigOptions } = viewDefinition;
  if (isDefaultConfigError(defaultConfigOptions)) {
    return {
      key: 'customConfig',
      title: i18n`Use default configuration cannot be used`,
      info: defaultConfigOptions.error,
      padded: true,
      type: EditionOptionTypes.checkbox,
      props: { checked: false, disabled: true },
    };
  } else {
    return {
      key: 'customConfig',
      title: i18n`Use concept view default configuration`,
      info: i18n`Selecting this option uses the predefined concept configuration. Deselecting it allows you to configure the view from scratch.`,
      padded: true,
      type: EditionOptionTypes.checkbox,
      props: {
        checked: defaultConfigOptions.checked,
        disabled: readOnly,
        onChange: (value: boolean) => updateViewDefinition((oldViewDefinition) => {
          const newViewDefinition = { ...oldViewDefinition };
          newViewDefinition.withCustomConfig = !value;
          return newViewDefinition;
        }),
      },
    };
  }
};
