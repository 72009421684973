import type { FunctionComponent } from 'react';
import type { SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import {
  getPathLastFieldInformation,
  getPathReturnedConceptDefinitionId,
  InstanceReferenceType,
  isDimensionStep,
  isMappingStep,
  PathStepType,
} from 'yooi-modules/modules/conceptModule';
import { ColorField, NumberField, TimeseriesNumberField } from 'yooi-modules/modules/conceptModule/ids';
import type { EditableViewSeriesProperties, ViewDimension, ViewSeries } from 'yooi-modules/modules/dashboardModule';
import { InterpolationType, ViewType } from 'yooi-modules/modules/dashboardModule';
import { joinObjects } from 'yooi-utils';
import { ButtonVariant } from '../../../../../components/atoms/Button';
import Checkbox from '../../../../../components/atoms/Checkbox';
import Icon, { IconColorVariant, IconName } from '../../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../../components/atoms/IconOnlyButton';
import Tooltip from '../../../../../components/atoms/Tooltip';
import Typo from '../../../../../components/atoms/Typo';
import CompositeField from '../../../../../components/molecules/CompositeField';
import SearchAndSelect from '../../../../../components/molecules/SearchAndSelect';
import SpacedContainer from '../../../../../components/molecules/SpacedContainer';
import SpacingLine from '../../../../../components/molecules/SpacingLine';
import DataTable from '../../../../../components/templates/DataTable';
import useStore from '../../../../../store/useStore';
import base from '../../../../../theme/base';
import { getSpacing, Spacing } from '../../../../../theme/spacingDefinition';
import i18n from '../../../../../utils/i18n';
import makeStyles from '../../../../../utils/makeStyles';
import useTheme from '../../../../../utils/useTheme';
import { getColorPalette } from '../../../../utils/standardColorsUtils';
import { getFieldHandler } from '../../../fields/FieldLibrary';
import StoreColorPickerInput from '../../../input/StoreColorPickerInput';
import StoreTextInputField from '../../../input/StoreTextInputField';
import { defaultOptionComparator } from '../../../modelTypeUtils';
import PathInput from '../../../path/PathInput';
import PathMappingAndFiltersInput from '../../../path/PathMappingAndFiltersInput';
import PathStepsInput from '../../../path/PathStepsInput';
import { createPathConfigurationHandler } from '../../../pathConfigurationHandler';
import { getFieldTypeValidator } from '../../../pathConfigurationHandlerUtils';
import DimensionExportConfiguration from './DimensionExportConfiguration';
import { getPathSeriesPathConfigurationHandler, getSeriesLabel, getViewDimensionsAsParameterDefinitions } from './viewWithSeriesFeatureUtils';
import WidthPicker from './WidthPicker';

interface ViewDefinitionSeriesOptionsProps {
  viewType: ViewType,
  series: ViewSeries[],
  onUpdateSeries: (seriesId: string, properties: Partial<EditableViewSeriesProperties>) => void,
  onCreateSeries: () => void,
  onDeleteSeries: (seriesId: string) => void,
  onMoveUpSeries: (index: number) => void,
  onMoveDownSeries: (index: number) => void,
  readOnly: boolean,
  parameterDefinitions: SingleParameterDefinition[],
  dimensions: ViewDimension[],
  withoutDisplayOptions?: boolean,
  withExportOptions?: boolean,
  defaultExportOptions?: object,
}

const useStyles = makeStyles({
  buttonOptions: {
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {
    display: 'flex',
  },
  exportOptionsContainer: {
    marginLeft: getSpacing(Spacing.splus),
  },
}, 'viewDefinitionSeriesOptions');

enum DisplayOptions {
  color = 'color',
  colorPath = 'colorPath',
  interpolation = 'interpolation',
  withArea = 'withArea',
  width = 'width',
  withLegend = 'withLegend',
}

const viewTypeToDisplayOptions: { [key in ViewType]: DisplayOptions[] } = {
  [ViewType.LineChart]: [DisplayOptions.color, DisplayOptions.colorPath, DisplayOptions.withLegend, DisplayOptions.interpolation, DisplayOptions.withArea],
  [ViewType.TemporalBarChart]: [DisplayOptions.color, DisplayOptions.colorPath, DisplayOptions.withLegend],
  [ViewType.StructuralBarChart]: [DisplayOptions.color, DisplayOptions.colorPath, DisplayOptions.withLegend],
  [ViewType.Gauge]: [DisplayOptions.color, DisplayOptions.colorPath, DisplayOptions.withLegend],
  [ViewType.Table]: [DisplayOptions.width],
  [ViewType.TimeseriesTable]: [DisplayOptions.width],
  [ViewType.Swimlane]: [],
  [ViewType.Matrix]: [],
  [ViewType.Timeline]: [],
  [ViewType.Cards]: [],
  [ViewType.Chip]: [],
};

const getSeriesDisplayOptionTitle = (viewType: ViewType, displayOptions: ViewSeries['displayOptions']) => {
  let count = 0;
  if (displayOptions) {
    count = Object.entries(displayOptions)
      .filter(([key]) => (viewTypeToDisplayOptions[viewType] as string[]).includes(key))
      .reduce<number>((acc, [, value]) => {
        if (value !== undefined && value !== false) {
          if (Array.isArray(value)) {
            if (value.length > 0) {
              return acc + 1;
            }
          } else {
            return acc + 1;
          }
        }
        return acc;
      }, 0);
  }

  if (count === 0) {
    return i18n`No options set`;
  } else if (count === 1) {
    return i18n`${count} option set`;
  } else {
    return i18n`${count} options set`;
  }
};

const ViewDefinitionSeriesOptions: FunctionComponent<ViewDefinitionSeriesOptionsProps> = ({
  viewType,
  series,
  onCreateSeries,
  onUpdateSeries,
  onDeleteSeries,
  onMoveUpSeries,
  onMoveDownSeries,
  readOnly,
  parameterDefinitions,
  dimensions,
  withoutDisplayOptions = false,
  withExportOptions = false,
  defaultExportOptions,
}) => {
  const classes = useStyles();

  const store = useStore();
  const theme = useTheme();

  const interpolationTypeOptions: Record<InterpolationType, { id: string, label: string }> = {
    linear: { id: InterpolationType.linear, label: i18n`Linear` },
    natural: { id: InterpolationType.natural, label: i18n`Natural` },
    step: { id: InterpolationType.step, label: i18n`Step` },
    stepAfter: { id: InterpolationType.stepAfter, label: i18n`Step after` },
    stepBefore: { id: InterpolationType.stepBefore, label: i18n`Step before` },
  };

  const valuePathHandler = getPathSeriesPathConfigurationHandler(store, parameterDefinitions, viewType);

  let columnNumber = 4;
  if (!withoutDisplayOptions) {
    columnNumber += 1;
  }
  if (withExportOptions) {
    columnNumber += 1.5;
  }
  const baseColumnWidth = 100 / columnNumber;

  return (
    <DataTable<ViewSeries>
      columnsDefinition={[
        {
          name: i18n`Label`,
          propertyId: 'Label',
          width: baseColumnWidth,
          cellRender: ({ id, label, path }, _, index) => (
            <StoreTextInputField
              initialValue={getSeriesLabel(store, label, index, path, dimensions, parameterDefinitions.map((parameter) => parameter.id))}
              onSubmit={(value) => {
                onUpdateSeries(id, { label: value ?? undefined });
              }}
              readOnly={readOnly}
            />
          ),
        },
        {
          name: i18n`Path`,
          propertyId: 'Path',
          width: 2 * baseColumnWidth,
          cellRender: ({ id, path }) => (
            <PathStepsInput
              initialPath={path}
              onSubmit={(newPath) => {
                onUpdateSeries(id, { path: newPath, exportOptions: null });
              }}
              readOnly={readOnly}
              valuePathHandler={valuePathHandler}
              parameterDefinitions={parameterDefinitions}
              suggestedBasePaths={
                getViewDimensionsAsParameterDefinitions(store, dimensions)
                  .map(({ id: parameterId, typeId, label }) => ({
                    label,
                    path: [
                      { type: PathStepType.dimension, conceptDefinitionId: typeId },
                      { type: PathStepType.mapping, mapping: { id: parameterId, type: InstanceReferenceType.parameter } },
                    ],
                  }))
              }
            />
          ),
        },
        {
          name: i18n`Filters`,
          propertyId: 'Filters',
          width: baseColumnWidth,
          cellRender: ({ id, path }) => (
            <PathMappingAndFiltersInput
              path={path}
              onChange={(newPath) => {
                onUpdateSeries(id, { path: newPath });
              }}
              readOnly={readOnly}
              valuePathHandler={valuePathHandler}
              parameterDefinitions={parameterDefinitions}
            />
          ),
        },
        ...!withoutDisplayOptions ? [
          {
            name: i18n`Display options`,
            propertyId: 'displayOptions',
            width: baseColumnWidth,
            cellRender: ({ id, displayOptions }: ViewSeries) => (
              <CompositeField
                width="50rem"
                readOnly={readOnly}
                headerLinesRenderers={[
                  {
                    id: 'Title',
                    render: () => {
                      const seriesDisplayOptionTitle = getSeriesDisplayOptionTitle(viewType, displayOptions);

                      return (
                        <SpacedContainer margin={{ y: Spacing.xs }}>
                          <div className={classes.buttonOptions}>
                            <SpacedContainer margin={{ right: Spacing.s }}>
                              <div className={classes.iconContainer}>
                                <Icon colorVariant={IconColorVariant.alternative} name={IconName.tune} />
                              </div>
                            </SpacedContainer>
                            <Tooltip title={seriesDisplayOptionTitle}>
                              <Typo maxLine={1}>{seriesDisplayOptionTitle}</Typo>
                            </Tooltip>
                          </div>
                        </SpacedContainer>
                      );
                    },
                  },
                ]}
                getDropdownSectionDefinitions={() => {
                  const valuePathConfigurationHandler = createPathConfigurationHandler(
                    store,
                    parameterDefinitions ?? [],
                    [
                      getFieldTypeValidator(store, [ColorField, NumberField, TimeseriesNumberField], i18n`Input should end with a color or a number field.`),
                    ]
                  );
                  const colorPalette = getColorPalette(store);

                  return [
                    {
                      id: 'DisplayOptions',
                      lines: viewTypeToDisplayOptions[viewType].map((displayOption) => {
                        if (displayOption === DisplayOptions.color) {
                          return {
                            id: displayOption,
                            isVertical: false,
                            title: i18n`Color`,
                            render: (
                              <SpacingLine>
                                <StoreColorPickerInput
                                  readOnly={readOnly}
                                  initialValue={displayOptions?.color}
                                  defaultValue={base.color.gray['100']}
                                  onSubmit={(newValue) => onUpdateSeries(id, {
                                    displayOptions: joinObjects((displayOptions ?? {}), { color: newValue ?? undefined }),
                                  })}
                                  colorPalette={colorPalette}
                                />
                              </SpacingLine>
                            ),
                          };
                        } else if (displayOption === DisplayOptions.colorPath) {
                          return {
                            id: displayOption,
                            isVertical: false,
                            title: i18n`Color Path`,
                            render: (
                              <PathInput
                                path={displayOptions?.colorPath ?? []}
                                valuePathHandler={valuePathConfigurationHandler}
                                parameterDefinitions={parameterDefinitions}
                                onChange={(newValue) => onUpdateSeries(id, {
                                  displayOptions: joinObjects((displayOptions ?? {}), { colorPath: newValue }),
                                })}
                              />
                            ),
                          };
                        } else if (displayOption === DisplayOptions.interpolation) {
                          return {
                            id: displayOption,
                            isVertical: false,
                            title: i18n`Interpolation`,
                            render: (
                              <SearchAndSelect
                                selectedOption={interpolationTypeOptions[displayOptions?.interpolation ?? InterpolationType.linear]}
                                computeOptions={() => Object.values(interpolationTypeOptions)
                                  .sort(defaultOptionComparator)}
                                onSelect={(option) => onUpdateSeries(id, {
                                  displayOptions: joinObjects(
                                    (displayOptions ?? {}),
                                    { interpolation: option?.id as InterpolationType | undefined }
                                  ),
                                })}
                              />
                            ),
                          };
                        } else if (displayOption === DisplayOptions.width) {
                          return {
                            id: displayOption,
                            isVertical: false,
                            title: i18n`Width`,
                            render: (
                              <WidthPicker
                                value={displayOptions?.width}
                                onChange={(newValue) => onUpdateSeries(id, { displayOptions: joinObjects((displayOptions ?? {}), { width: newValue }) })}
                              />
                            ),
                          };
                        } else if (displayOption === DisplayOptions.withLegend) {
                          return {
                            id: displayOption,
                            isVertical: false,
                            title: i18n`Displayed in legend`,
                            render: (
                              <SpacingLine>
                                <Checkbox
                                  checked={Boolean(displayOptions?.withLegend)}
                                  onChange={(value) => {
                                    onUpdateSeries(id, {
                                      displayOptions: joinObjects((displayOptions ?? {}), { withLegend: value }),
                                    });
                                  }}
                                />
                              </SpacingLine>
                            ),
                          };
                        } else {
                          return {
                            id: displayOption,
                            isVertical: false,
                            title: i18n`With area`,
                            render: (
                              <SpacingLine>
                                <Checkbox
                                  checked={Boolean(displayOptions?.withArea)}
                                  onChange={(check) => onUpdateSeries(id, {
                                    displayOptions: joinObjects((displayOptions ?? {}), { withArea: check }),
                                  })}
                                />
                              </SpacingLine>
                            ),
                          };
                        }
                      }),
                    },
                  ];
                }}
              />
            ),
          },
        ] : [],
        ...withExportOptions ? [
          {
            propertyId: 'Export',
            name: i18n`Export format`,
            width: baseColumnWidth * 1.5,
            cellRender: ({ id, path, exportOptions }: ViewSeries) => {
              const { fieldId } = getPathLastFieldInformation(path) ?? {};
              if (!fieldId) {
                const firstStep = path.at(0);
                const secondStep = path.at(1);
                if (path.length === 2 && isDimensionStep(firstStep) && isMappingStep(secondStep)) {
                  return (
                    <DimensionExportConfiguration
                      configuration={(exportOptions ?? undefined) as DimensionExportConfiguration | undefined}
                      conceptDefinitionId={firstStep.conceptDefinitionId}
                      onChange={(newConfiguration) => onUpdateSeries(id, { exportOptions: newConfiguration as unknown as Record<string, unknown> })}
                    />
                  );
                }
                return (
                  <div className={classes.exportOptionsContainer}>
                    <Typo color={theme.color.text.disabled}>{i18n`Not supported`}</Typo>
                  </div>
                );
              }
              return getFieldHandler(store, fieldId)?.renderExportConfiguration?.({
                configuration: exportOptions ?? defaultExportOptions,
                conceptDefinitionId: getPathReturnedConceptDefinitionId(store, path),
                onChange: (newConfiguration) => onUpdateSeries(id, { exportOptions: newConfiguration }),
              }) ?? (
                <div className={classes.exportOptionsContainer}>
                  <Typo color={theme.color.text.disabled}>{i18n`Not supported`}</Typo>
                </div>
              );
            },
          },
        ] : [],
        {
          propertyId: 'MoveUp',
          action: true,
          cellRender: (_, __, index) => (
            <SpacingLine>
              <IconOnlyButton
                disabled={readOnly || index === 0}
                onClick={() => onMoveUpSeries(index)}
                iconName={IconName.expand_less}
                tooltip={i18n`Move Up`}
                variant={IconOnlyButtonVariants.tertiary}
              />
            </SpacingLine>
          ),
        }, {
          propertyId: 'MoveDown',
          action: true,
          cellRender: (_, __, index) => (
            <SpacingLine>
              <IconOnlyButton
                disabled={readOnly || index === series.length - 1}
                onClick={() => onMoveDownSeries(index)}
                iconName={IconName.expand_more}
                tooltip={i18n`Move Down`}
                variant={IconOnlyButtonVariants.tertiary}
              />
            </SpacingLine>
          ),
        },
        {
          propertyId: 'Delete',
          action: true,
          cellRender: ({ id }) => (
            <SpacingLine>
              <IconOnlyButton
                disabled={readOnly}
                onClick={() => onDeleteSeries(id)}
                iconName={IconName.delete}
                tooltip={i18n`Delete`}
                variant={IconOnlyButtonVariants.danger}
              />
            </SpacingLine>
          ),
        },
      ]}
      list={series.map((item) => ({ key: item.id, type: 'item', item, color: undefined }))}
      newItemIcon={IconName.add}
      newItemTitle={i18n`Add`}
      newItemButtonVariant={ButtonVariant.tertiary}
      onNewItem={readOnly ? undefined : () => onCreateSeries()}
      fullWidth
    />
  );
};

export default ViewDefinitionSeriesOptions;
